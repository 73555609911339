body{
	font-family: "Avenir LT W01 45 Book", Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

strong, b{
	font-family: "Avenir LT W01 85 Heavy", Arial, 'Helvetica Neue', Helvetica, sans-serif;
}
.margin-right{
	margin-right: 10px;
}

.memorial-logo-container a
{
	display: block;
	background: url(images/mun-login-sprite.png) 0 0 no-repeat;
	background-size: 560px auto;
	height: 80px;
	width: 130px;
	margin: 10px auto;
}

.memorial-logo-container a span
{
	display: none;
}

.memorial-logo-container h1
{
	font-family: "Avenir LT W01 95 Black", Arial, 'Helvetica Neue', Helvetica, sans-serif;
	font-size: 2.5em;
	color: #404041;
	margin: 10px 0 20px 0;
}


.btn-primary{
	background-color: #404041;
	border-color: #303031;
}

.btn-primary:hover, .btn-primary[disabled]{
	background-color: #303031;
}
.btn-primary:focus{
	background-color: #303031;
}

.ok-not-complete{
	color: #b0b0b0;
}
.ok-complete {
	color: #83ce40;
}


.mun-login-in-password, .space-in-password{
	color: #ff0000;
	font-weight: bold;
}

.popover{
	max-width: 300px;
}

/* Note: Design for a width of 768px */
@media only screen and (min-width: 768px){

	.memorial-logo-container h1
	{
		font-size: 4em;
		margin: 20px 0 30px 0;

	}

}

.well a.blue{
	color: #0000ff;
}


form.mun-login-credentials{
	min-height: 300px;
}


.button-loading{
	display: inline-block;
	background-image:url(images/button-loading.gif);
	height: 11px;
	width: 16px;
}


/* Sticky footer styles
-------------------------------------------------- */
html {
	position: relative;
	min-height: 100%;
}
body {
	/* Margin bottom by footer height */
	margin-bottom: 60px;
}
.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	/* Set the fixed height of the footer here */
	height: 60px;
	background-color: #404041;
	color: #ffffff;
	font-size: 0.7em;
	padding-top:10px;
}
.footer a{
	color: #ffffff;
}

/* modal styles
   ------------ */
#cc-modal .footer-buttons{
	display:none;
}

#cc-modal-alert{
	margin:10px 20px 0 20px;
}



/* MUN Web Fonts
   --------------------------------------------------

@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=20d74cc5-7f7c-49a7-80ae-fa2f389c550d");
@font-face{
    font-family:"Avenir LT W01 45 Book";
    src:url("//clf.mun.ca/fonts/avenir-garamond/fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix");
    src:url("//clf.mun.ca/fonts/avenir-garamond/fonts/4f32268f-fd86-4960-b72c-4bb1ba75ec6f.eot?#iefix") format("eot"),url("//clf.mun.ca/fonts/avenir-garamond/fonts/939cba03-5b40-4d01-9bc5-7589eca863db.woff") format("woff"),url("//clf.mun.ca/fonts/avenir-garamond/fonts/849bc5b9-a2ff-4343-977b-26ba8bd24a60.ttf") format("truetype"),url("//clf.mun.ca/fonts/avenir-garamond/fonts/f67fa3b5-c1d1-488f-8e60-a828b9ad56a4.svg#f67fa3b5-c1d1-488f-8e60-a828b9ad56a4") format("svg");
}
@font-face{
    font-family:"Avenir LT W01 85 Heavy";
    src:url("//clf.mun.ca/fonts/avenir-garamond/fonts/f61bf0f6-c193-4100-b940-12a1a01dcee5.eot?#iefix");
    src:url("//clf.mun.ca/fonts/avenir-garamond/fonts/f61bf0f6-c193-4100-b940-12a1a01dcee5.eot?#iefix") format("eot"),url("//clf.mun.ca/fonts/avenir-garamond/fonts/7147ec3a-8ff8-4ec9-8c5c-bd1571dc6ae6.woff") format("woff"),url("//clf.mun.ca/fonts/avenir-garamond/fonts/d1dc54b2-878d-4693-8d6e-b442e99fef68.ttf") format("truetype"),url("//clf.mun.ca/fonts/avenir-garamond/fonts/731dd4d3-64da-427d-ba61-01575b3cf3f7.svg#731dd4d3-64da-427d-ba61-01575b3cf3f7") format("svg");
}
@font-face{
    font-family:"Avenir LT W01 95 Black";
    src:url("//clf.mun.ca/fonts/avenir-garamond/fonts/a24b3f55-c8c8-41a1-a77e-7cdf417d82f6.eot?#iefix");
    src:url("//clf.mun.ca/fonts/avenir-garamond/fonts/a24b3f55-c8c8-41a1-a77e-7cdf417d82f6.eot?#iefix") format("eot"),url("//clf.mun.ca/fonts/avenir-garamond/fonts/5a05cf7f-f332-456f-ae67-d8c26e0d35b3.woff") format("woff"),url("//clf.mun.ca/fonts/avenir-garamond/fonts/c33278bc-988e-440b-b4be-6acc095e6044.ttf") format("truetype"),url("//clf.mun.ca/fonts/avenir-garamond/fonts/7bdad4a5-289b-42f9-b6fa-c5883b9e9f23.svg#7bdad4a5-289b-42f9-b6fa-c5883b9e9f23") format("svg");
}
*/
